import {Button, Form, Input, InputNumber, message, Modal, Space} from "antd";
import {useEffect, useState} from "react";
import {baseAxiosInstance} from "../../../axios/axiosBase";
import axios from "axios";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";

export const AdjustECoinModal = ({ userId, refetchUser }: { userId: string, refetchUser: any }) => {

    const {token} = useCredentialStore(({token}) => ({
        token
    }));

    const [coinValue, setCoinValue] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onAddCoin = () => {
        axios.post(`/api/user/${userId}/e-coin/adjust`, {
            amount: coinValue,
            increase: true
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(() => {
                message.success("Adjust coin successfully")
                refetchUser();
                setIsModalOpen(false);
            })
    }

    const onMinusCoin = () => {
        axios.post(`/api/user/${userId}/e-coin/adjust`, {
            amount: coinValue,
            increase: false
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(() => {
                message.success("Adjust coin successfully")
                refetchUser();
                setIsModalOpen(false);
            })
    }

    return (
        <>
            <Button type="primary" onClick={showModal}>
                Adjust
            </Button>
            <Modal title="Adjust" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
                <Form
                    name="adjustCoin"
                    autoComplete="off"
                >
                    <Form.Item
                        label="Number of Coins"
                        name="coins"
                        rules={[{ required: true, message: 'Please input your coins!' }]}
                    >
                        <InputNumber onChange={(value) => {
                            setCoinValue(+value)
                        }} />
                    </Form.Item>

                    <Space direction={"horizontal"} align={"center"}>
                        <Button type="primary" htmlType="button" onClick={onAddCoin}>
                            Add
                        </Button>
                        <Button type="primary" htmlType="button" onClick={onMinusCoin}>
                            Minus
                        </Button>
                    </Space>
                </Form>
            </Modal>
        </>
    )
}
