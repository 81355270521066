import React, {useEffect, useState} from 'react';
import {Image, Select} from 'antd';
import './index.css';
import { Form } from 'antd';
import {Col, Input, Container, Row, Table} from 'reactstrap';
import "flatpickr/dist/themes/material_green.css";
import axios from "axios";
import {PopNotification} from "../../../shared/notification";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";
import {getChargerProps} from "../../../types/superAdmin";
import {
    getModelsResponse,
    getVendorResponse
} from "../../../types/admin";
import {ChargerReport} from "../ChargerReport";


const ChargerDetails: React.FC = ({ children }) => {

    const { proxy } = require("../../../../package.json");
    const {Option} = Select;
    const [selectedVendorName, setSelectedVendorName] = useState("");
    const [selectedModelName, setSelectedModelName] = useState("");
    const [profileImageId, setProfileImageId] = useState<string | null>(null);
    const [selectedPriceMode, setSelectedPriceMode] = useState<string>("RATE");
    const [loading, setLoading] = useState(false);
    const [initChargerDetails, setInitChargerDetails] = useState<getChargerProps>()
    const [form] = Form.useForm();
    const [socketTypeForm] = Form.useForm();
    const { getGroups, token } = useCredentialStore(({ getGroups, token }) => ({
        getGroups,
        token
    }));
    const [ActiveTab, setActiveTab] = useState("settings");
    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get("chargerId")

    const getChargerDetails = () => {
        axios
            .get(`/api/chargerWithSockets/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                setProfileImageId(res.data.imageId.id)
                setInitChargerDetails(res.data);

                // Vendor Name
                getVendorNameBasedOnId(res.data.vendorId.id)

                // Model Name
                getModelNameBasedOnId(res.data.modelId.id, res.data.vendorId.id)

            })
            .catch((error) => {
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {
                // setLoading(false);
            });
    }

    const getModelNameBasedOnId = (modelId: string, vendorId: string) => {
        axios
            .get(`/api/charger/models?page=0&pageSize=100&vendorId=${vendorId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                const modelList: getModelsResponse = res.data;
                for (let i = 0; i < modelList.data.length; i++) {
                    if (modelList.data[i].id.id === modelId) {
                        setSelectedModelName(modelList.data[i].name)
                    }
                }
            })
            .catch((error) => {
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {
                // setLoading(false);
            });
    }

    const getVendorNameBasedOnId = (vendorId: string) => {
        axios
            .get("/api/charger/vendors?page=0&pageSize=100", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                const vendorList: getVendorResponse = res.data;
                for (let i = 0; i < vendorList.data.length; i++) {
                    if (vendorList.data[i].id.id === vendorId) {
                        setSelectedVendorName(vendorList.data[i].name)
                    }
                }
            })
            .catch((error) => {
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {
                // setLoading(false);
            });
    }

    useEffect(() => {
        getChargerDetails();
    }, []);

    return (
        <div>
            <span className='page-title'>Charger: {initChargerDetails?.uniqueId}</span>
            <div style={{ display: "flex", backgroundColor: "#C4C4C4", borderRadius: 50, overflow: "hidden" }}>
                <div onClick={() => {
                    setActiveTab("settings");
                }} style={{ width: "50%", textAlign: "center", backgroundColor: ActiveTab === "settings" ? "#39B64B" : "#c4c4c4", padding: 10, borderRadius: 50, cursor: "pointer" }}>
                    <span style={{ textTransform: "uppercase", fontWeight: "bold", color: "white" }}>Settings</span>
                </div>
                <div onClick={() => {
                    setActiveTab("reports");
                }} style={{
                    width: "50%",
                    textAlign: "center",
                    backgroundColor: ActiveTab === "reports" ? "#39B64B" : "#c4c4c4",
                    padding: 10,
                    borderRadius: 50,
                    cursor: "pointer"
                }}>
                    <span style={{textTransform: "uppercase", fontWeight: "bold", color: "white"}}>reports</span>
                </div>
            </div>
            {
                ActiveTab === "settings" ? <div>
                    <Container fluid={true} style={{paddingLeft: 0, paddingRight: 0}}>
                        <Form form={form}
                              layout="vertical"
                              onFinish={() => {}}>
                            <Row>
                                <Col md={6}>
                                    <div style={{marginTop: 20,}}>
                                        <Image
                                            preview={false}
                                            width={200}
                                            src={
                                                profileImageId === null ?
                                                    "https://images.unsplash.com/photo-1593642532400-2682810df593?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80"
                                                    : `/api/image/` + profileImageId
                                            }
                                        />
                                    </div>
                                    <div style={{marginTop: 20,}}>
                                        <span className='id-css'>Unique ID: {initChargerDetails?.uniqueId}</span>
                                        <span className='heading-page'>General Charger Details</span>
                                        <div style={{margin: "10px 0px",}}>
                                            <Input
                                                id="exampleSelect1"
                                                name="select"
                                                type="select"
                                                placeholder='Vendor'
                                                disabled={true}
                                                // onChange={(e) => setSelectedVendorId(e.target.value)}
                                            >
                                                <option>
                                                    {selectedVendorName}
                                                </option>
                                            </Input>
                                        </div>
                                        <div style={{margin: "10px 0px",}}>
                                            <Input
                                                id="exampleSelect2"
                                                name="select"
                                                type="select"
                                                placeholder='Model'
                                                disabled
                                                // onChange={(e) => setSelectedModelId(e.target.value)}
                                            >
                                                <option>
                                                    { selectedModelName }
                                                </option>
                                            </Input>
                                        </div>
                                        <div style={{margin: "10px 0px",}}>
                                            <Input
                                                placeholder="Serial No"
                                                className='input-box'
                                                value={initChargerDetails?.serialNumber}
                                                disabled
                                            />
                                        </div>
                                        <span className='heading-page'>Charging socket</span>
                                        <Row md={12} style={{margin: 1}}>
                                            <Col md={12} className='card-datavisulization'
                                                 style={{textAlign: "left", paddingTop: 10, height: "auto"}}>
                                                <Table bordered responsive>
                                                    <thead>
                                                    <tr>
                                                        {/*<td>*/}
                                                        {/*    Socket Id*/}
                                                        {/*</td>*/}
                                                        <th>
                                                            Socket Type
                                                        </th>
                                                        <th>
                                                            Current Type
                                                        </th>
                                                        <th>
                                                            Power Rating
                                                        </th>
                                                        {/*<th>*/}
                                                        {/*    Action*/}
                                                        {/*</th>*/}
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        initChargerDetails?.socketList?.map((socketTypeDetail) => (
                                                            <tr>
                                                                <td>
                                                                    {socketTypeDetail.type.name}
                                                                </td>
                                                                <td>
                                                                    {socketTypeDetail.currentType}
                                                                </td>
                                                                <td>
                                                                    {socketTypeDetail.powerRating}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                        {/*<Button block type="primary" htmlType="submit" className="login-form-button">*/}
                                        {/*    Add Charger*/}
                                        {/*</Button>*/}
                                    </div>
                                </Col>
                                <Col md={6}>

                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </div> : <ChargerReport />
            }
        </div>
    )
}

export default ChargerDetails
