import React, {useEffect} from "react";
import {Button, Input, Select, Space, Table} from "antd";
import {AdjustECoinModal} from "../../../components/Model/AdjustECoinModal";
import axios from "axios";
import {getUsersResponse} from "../../../types/admin";
import {PopNotification} from "../../../shared/notification";
import {baseAxiosInstance} from "../../../axios/axiosBase";
import {ECoinLogModal} from "./ECoinLog";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";

export const ECoinTrackingPage = () => {

    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const [flag, setFlag] = React.useState(false)

    const { token } = useCredentialStore(({ token }) => ({ token }));

    const [loading, setLoading] = React.useState(false);

    const [selectedUserId, setSelectedUserId] = React.useState();

    const [userList, setMainUserList] = React.useState<getUsersResponse["data"]>([]);

    const [currentPage, setCurrentPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [totalElements, setTotalElements] = React.useState(0);

    const [dropDownValue, setDropDownValue] = React.useState("all");

    const [sortDirection, setSortDirection] = React.useState<"asc" | "desc">("asc");

    const [searchText, setSearchText] = React.useState("")

    const onGetAllUsers = () => {

        let url = ""

        if (searchText) {
            url = `/api/users?page=${currentPage}&pageSize=${pageSize}&textSearch=${searchText}`
        } else {
            url = `/api/users?page=${currentPage}&pageSize=${pageSize}`
        }

        axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((res) => {
                const userList: getUsersResponse = res.data;
                setMainUserList(userList.data);
                setTotalElements(userList.totalElements);
                setFlag(!flag)
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const columns = [
        {
            title: "No.",
            dataIndex: "index",
            key: 'index',
            render: (text: string, record: any, index: number) => {
                return (currentPage) * pageSize + index + 1
            }
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Coin Balance',
            dataIndex: 'coinBalance',
            key: 'coinBalance',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: any) => {
                return (
                    <Space direction={"horizontal"}>
                        <AdjustECoinModal userId={record.id.id} refetchUser={() => {
                            onGetAllUsers()
                            setFlag(!flag)
                        }} />
                        <Button onClick={() => {
                            setSelectedUserId(record.id.id)
                            setIsModalOpen(true)
                        }}>Transaction Log</Button>
                    </Space>
                )
            }
        }
    ];

    const onClickSort = () => {
        setSearchText("")
        axios.get(`/api/users?page=${currentPage}&pageSize=${pageSize}&sortProperty=${dropDownValue}&sortOrder=${sortDirection}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((res) => {
                const userList: getUsersResponse = res.data;
                setMainUserList(userList.data);
                setTotalElements(userList.totalElements);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        onGetAllUsers()
    }, [currentPage, pageSize, searchText])

    return (
        <Space direction={"vertical"} style={{width: '100%'}}>
            <span className='page-title'>E-Coin Tracking</span>

            <Input onChange={(e) => setSearchText(e.target.value)} placeholder={"Search Text"}/>

            <Space direction={"horizontal"}>
                <Select
                    style={{width: 120}}
                    onChange={(value) => setDropDownValue(value)}
                    placeholder={"Sort Field"}
                    options={[
                        {
                            value: 'firstName',
                            label: 'First Name',
                        },
                        {
                            value: 'lastName',
                            label: 'Last Name',
                        },
                        {
                            value: 'email',
                            label: 'Email',
                        },
                        {
                            value: 'mobileNumber',
                            label: 'Mobile Number',
                        },
                        {
                            value: 'coinBalance',
                            label: 'Coin Balance',
                        },
                    ]}
                />

                <Select
                    style={{width: 120}}
                    onChange={(value) => setSortDirection(value)}
                    placeholder={"Sort Order"}
                    options={[
                        {
                            value: 'asc',
                            label: 'Ascending',
                        },
                        {
                            value: 'desc',
                            label: 'Descending',
                        },
                    ]}
                />

                <Button onClick={() => onClickSort()}>Sort</Button>
            </Space>

            <Table
                dataSource={userList}
                columns={columns}
                pagination={{
                    total: totalElements,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => {
                        setCurrentPage(page - 1);
                        setPageSize(pageSize);
                        setFlag(!flag);
                    }
                }}
            />
            {
                isModalOpen && (
                    <ECoinLogModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} userId={selectedUserId ?? ""} flag={flag}/>
                )
            }
        </Space>
    )
}
